h3 {
	padding: 1rem 0 1rem 0;
}

main {
	min-height: 80vh;
}

#linkedinBadge {
	font-size: 40px;
	font-style: italic;
	font-weight: bold;
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
